import { DropdownOption } from "@modernatx/ui-kit-react";
import { useRouter } from "next/router";
import React from "react";

import { useExperience } from "@/context/ExperienceContext";
import { FinderLocation } from "@/types/FinderLocation";

type SearchType = "unbranded" | "branded";

interface Place {
  address: string;
  id: string;
  latitude: number;
  longitude: number;
}

interface FinderContextValue {
  locationSelected: FinderLocation | null;
  locationSelectedSet: (location: FinderLocation) => void;
  place: Place | null;
  productsSelect: (products: string[]) => void;
  productsSelected: string[];
  searching: boolean;
  searchOptionSelect: (option: DropdownOption) => void;
  searchOptionSelected: DropdownOption | null;
  searchResults: FinderLocation[];
  searchType: SearchType;
  searchValue: string;
  searchValueSet: (value: string) => void;
}

const FinderContext = React.createContext<FinderContextValue>({
  locationSelected: null,
  locationSelectedSet: () => {},
  place: null,
  productsSelect: () => {},
  productsSelected: [],
  searching: false,
  searchOptionSelect: () => {},
  searchOptionSelected: null,
  searchResults: [],
  searchType: "branded",
  searchValue: "",
  searchValueSet: () => {}
});

const getProductsSelected = (query: ReturnType<typeof useRouter>["query"], products: string[]) => {
  const productsQuery = Array.isArray(query.products) ? query.products[0] : query.products;
  return productsQuery?.split(",").filter((p) => products.includes(p)) || [];
};

export const FinderContextProvider: React.FC<
  React.PropsWithChildren<{
    products: string[];
    searchType: SearchType;
    radius?: number;
  }>
> = ({ children, searchType, products, radius }) => {
  const { country, language, alternates } = useExperience();
  const { pathname, query, push, replace } = useRouter();
  const currentPathname = React.useMemo(() => {
    const alternateMatch = alternates?.find(
      (alternate) => alternate.country === country && alternate.language === language
    );
    if (!alternateMatch) {
      return pathname;
    } else {
      const { url } = alternateMatch;
      const newPathname = new URL(url).pathname;
      return newPathname;
    }
  }, [alternates, country, language, pathname]);
  const [locationSelected, locationSelectedSet] = React.useState<FinderLocation | null>(null);
  const [place, placeSet] = React.useState<null | Place>(null);
  const [productsSelected, productsSelectedSet] = React.useState<string[]>(
    getProductsSelected(query, products)
  );
  const [searching, searchingSet] = React.useState(false);
  const [searchOptionSelected, searchOptionSelect] = React.useState<DropdownOption | null>(null);
  const [searchResults, searchResultsSet] = React.useState<FinderLocation[]>([]);
  const [searchValue, searchValueSet] = React.useState("");
  const placeId = React.useRef<null | string>(null);
  const placeIdQuery = Array.isArray(query.placeId) ? query.placeId[0] : query.placeId;
  const productsQueryInitial = productsSelected.join(",");
  const userInteracted = React.useRef(false);

  const setNewPlace = React.useCallback((newPlace: Place | null) => {
    placeSet(newPlace);
    locationSelectedSet(null);
    searchResultsSet([]);
  }, []);

  const setNewSearchResults = React.useCallback((newSearchResults: FinderLocation[]) => {
    searchResultsSet(newSearchResults);
    locationSelectedSet(null);
  }, []);

  React.useEffect(() => {
    if (placeId.current !== placeIdQuery && country && language) {
      if (placeIdQuery) {
        placeId.current = placeIdQuery;

        const searchParams = new URLSearchParams({
          country,
          language,
          placeId: placeIdQuery
        });
        fetch(`/api/location-details?${searchParams.toString()}`)
          .then((response) => {
            if (response.status >= 400) {
              throw new Error("Failed to fetch place.");
            }
            return response;
          })
          .then((response) => response.json())
          .then((data: Place) => {
            setNewPlace(data);

            if (!userInteracted.current) {
              searchValueSet(data.address);
            }

            userInteracted.current = false;
          })
          .catch(() => {
            setNewPlace({
              address: "Place not found.",
              id: "404",
              latitude: 0,
              longitude: 0
            });
          });
      } else {
        setNewPlace(null);
        searchValueSet("");
      }
    }
  }, [country, language, placeIdQuery, setNewPlace]);

  const fetchPartnerLocations = React.useCallback(
    async (params: URLSearchParams) => {
      const tokenResponse = await fetch("/api/auth/token");
      const { token, partnerApiGatewayUrl } = await tokenResponse.json();

      try {
        const response = await fetch(`${partnerApiGatewayUrl}?${params.toString()}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        });

        const data = await response.json();
        const locations: FinderLocation[] = data.map((location: FinderLocation) => {
          return {
            ...location,
            id: location.customer.id
          };
        });
        return locations;
      } catch (error) {
        setNewSearchResults([]);
      }
      return [];
    },
    [setNewSearchResults]
  );

  const fetchLocations = React.useCallback(
    async (params: URLSearchParams) => {
      try {
        const response = await fetch(`/api/product-locations?${params.toString()}`);
        if (response.status >= 400) {
          throw new Error("Failed to fetch locations.");
        }
        const { locations }: { locations: FinderLocation[] } = await response.json();
        if (params.get("type") === "unbranded" && country === "us") {
          const filteredLocations = locations.filter(
            (location: FinderLocation) =>
              !location.customer.name.toLowerCase().includes("walgreens")
          );
          return filteredLocations;
        }
        return locations;
      } catch (error) {
        setNewSearchResults([]);
      }
      return [];
    },
    [setNewSearchResults, country]
  );

  React.useEffect(() => {
    if (place) {
      const productsQuery = searchType === "unbranded" ? "unbranded" : productsQueryInitial;
      searchingSet(true);

      const searchParams = new URLSearchParams({
        country: country as string,
        lat: String(place.latitude),
        lon: String(place.longitude),
        products: productsQuery,
        type: searchType,
        radius: radius?.toString() || ""
      });

      const partnerLocationParams = new URLSearchParams({
        lat: String(place.latitude),
        lon: String(place.longitude)
      });

      const getLocations = async () => {
        let resolvedLocations: FinderLocation[] = [];

        try {
          if (searchType === "unbranded" && country === "us") {
            const partnerLocationsPromise = fetchPartnerLocations(partnerLocationParams).catch(
              () => {
                return [];
              }
            );

            const unbrandedLocationsPromise = fetchLocations(searchParams).catch(() => {
              return [];
            });

            // Fetch both locations concurrently
            const [partnerResults, unbrandedResults] = await Promise.all([
              partnerLocationsPromise,
              unbrandedLocationsPromise
            ]);

            resolvedLocations = [...partnerResults, ...unbrandedResults];
          } else {
            resolvedLocations = await fetchLocations(searchParams).catch(() => {
              return [];
            });
          }

          // Sort locations by distance and return top 10
          resolvedLocations.sort((a, b) => a.location.distance - b.location.distance);
          resolvedLocations = resolvedLocations.slice(0, 10);

          setNewSearchResults(resolvedLocations);
        } catch (error) {
          setNewSearchResults([]);
        } finally {
          searchingSet(false); // Ensure the searching state is reset
        }
      };

      getLocations();
    }
  }, [
    country,
    fetchLocations,
    fetchPartnerLocations,
    place,
    productsQueryInitial,
    radius,
    searchType,
    setNewSearchResults
  ]);

  const handleSearchOptionSelect = React.useCallback(
    (option: DropdownOption) => {
      userInteracted.current = true;
      searchOptionSelect(option);
      searchValueSet(option.label);
      push(
        {
          pathname: currentPathname,
          query: {
            ...query,
            placeId: option.value
          }
        },
        undefined,
        { scroll: false }
      );
    },
    [currentPathname, push, query]
  );

  const handleProductsSelect = React.useCallback(
    (productsNext: string[]) => {
      const productsFiltered = getProductsSelected({ products: productsNext.join(",") }, products);
      productsSelectedSet(productsFiltered);
      // Remove the products from the query in case all configuration has been removed.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { products: productsPrev, ...queryOther } = query;
      replace(
        {
          query: {
            ...queryOther,
            ...(!!productsFiltered.length ? { products: productsFiltered.join(",") } : {})
          }
        },
        undefined,
        { scroll: false }
      );
    },
    [products, replace, query]
  );

  return (
    <FinderContext.Provider
      value={{
        locationSelected,
        locationSelectedSet,
        place,
        productsSelect: handleProductsSelect,
        productsSelected,
        searching,
        searchOptionSelect: handleSearchOptionSelect,
        searchOptionSelected,
        searchResults,
        searchType,
        searchValue,
        searchValueSet
      }}
    >
      {children}
    </FinderContext.Provider>
  );
};

export const useFinder = () => React.useContext(FinderContext);
