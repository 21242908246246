import { keyframes } from "@emotion/react";
import { Box, Icon, Text, ThemeOverride } from "@modernatx/ui-kit-react";
import React from "react";

import { NavLink } from "@/components/navigation/NavLink";
import { FinderBlockProps } from "@/types/Block";

import { FinderHeader } from "./FinderHeader";
import { FinderList } from "./FinderList";
import { FinderListViewWrapper } from "./FinderListViewWrapper";
import { FinderMap } from "./FinderMap";
import { FinderContextProvider, useFinder } from "./hooks/useFinder";
import { useIsMobile } from "./hooks/useIsMobile";

export const FinderWithoutContext: React.FC<Omit<FinderBlockProps, "searchType">> = ({
  distanceTemplate,
  header,
  noResultsText = "No results found",
  finderListOptions,
  map
}) => {
  const { locationSelected, place, searching, searchResults } = useFinder();
  const [selectedView, setSelectedView] = React.useState<"map" | "list">("map");
  const isMobileView = useIsMobile();
  const isMobileMapView = isMobileView && selectedView === "map";

  const productOptions = header.selectOptions || [];

  const handleNavClick = (view: "map" | "list") => {
    setSelectedView(view);
  };

  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

  React.useEffect(() => {
    if (!isMobileView) {
      setSelectedView("map");
    }
  }, [isMobileView]);

  return (
    <>
      {header && <FinderHeader {...header} />}
      {map && (
        // mobile only toggle menu
        <ThemeOverride mode="light">
          <Box
            sx={{
              background: "background01",
              display: ["flex", "flex", "none"],
              justifyContent: "space-around"
            }}
          >
            <NavLink
              index={0}
              active={selectedView === "list"}
              onClick={() => handleNavClick("list")}
            >
              List
            </NavLink>
            <NavLink
              index={1}
              active={selectedView === "map"}
              onClick={() => handleNavClick("map")}
            >
              Map
            </NavLink>
          </Box>
          <Box
            id="finder-map"
            sx={{
              display: "flex",
              flexDirection: ["column", null, "row"],
              height: "100%",
              flexGrow: 2,
              position: "relative"
            }}
          >
            <FinderMap results={searchResults} hidden={selectedView === "list"} {...map} />
            <FinderListViewWrapper isMobileView={isMobileView}>
              {!searchResults.length && place && (
                <Box
                  sx={{
                    background: "background01",
                    borderRadius: [0, null, "large"],
                    textAlign: "center",
                    width: "100%",
                    px: 4,
                    py: searching ? 8 : 4
                  }}
                >
                  <Text size="lg" variant={"bold"}>
                    {searching ? (
                      <Icon
                        icon="spinner"
                        size="lg"
                        sx={{
                          animation: `${spin} 1s steps(8, end) infinite`
                        }}
                      />
                    ) : (
                      noResultsText
                    )}
                  </Text>
                </Box>
              )}

              <FinderList
                defaultOpen={isMobileMapView}
                distanceTemplate={distanceTemplate}
                productOptions={productOptions}
                // only show one location at a time on mobile map view, otherwise show all locations
                locations={
                  !isMobileMapView ? searchResults : locationSelected ? [locationSelected] : []
                }
                {...finderListOptions}
              />
            </FinderListViewWrapper>
          </Box>
        </ThemeOverride>
      )}
    </>
  );
};

export const Finder: React.FC<FinderBlockProps> = ({ searchType, radius, ...props }) => {
  const { selectOptions } = props.header;
  const products: string[] = React.useMemo(() => {
    return selectOptions?.map((option) => option.name) || [];
  }, [selectOptions]);

  return (
    <FinderContextProvider products={products} searchType={searchType} radius={radius}>
      <FinderWithoutContext {...props} />
    </FinderContextProvider>
  );
};
